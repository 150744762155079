export const environment = {
    production: true,
    hmr       : false,
    firebaseConfig : {
        apiKey: "AIzaSyAMq7-zt45SnoZZJXSKDCwEKkjNZnWirgk",
        authDomain: "oncloud-apps-6b9b2.firebaseapp.com",
        projectId: "oncloud-apps-6b9b2",
        storageBucket: "oncloud-apps-6b9b2.appspot.com",
        messagingSenderId: "1048563736936",
        appId: "1:1048563736936:web:968f8a199cf6acec51f6dd",
        measurementId: "G-5YNZSCM28H"
    }
};
