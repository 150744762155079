import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'apps',
    title: 'Aplicações',
    translate: 'NAV.APPS',
    type: 'item',
    icon: 'dashboard',
    url: '/pages/apps'
  }
];
