import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService} from './auth.service'
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

         return this.authService.user$.pipe(
            take(1),
            map(user => user && user.roles.admin ? true : false),
            tap(isAdmin => {
              if (!isAdmin) {
                console.error('Access denied - Admins only')
                this.router.navigate(['/pages/auth/login']);
            }
            })
          );
             
  }
}
