import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './user.model';

// import { auth } from 'firebase/app';
import auth from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {

    user$: Observable<User>;

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router
    ) 
    { 
        this.user$ = this.afAuth.authState.pipe(
            switchMap(user => {
                console.log(user);
              if (user) {
                return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
              } else {
                return of(null);
              }
            })
        )
    }


    async login(email: string, password: string) {
        var result = await this.afAuth.signInWithEmailAndPassword(email, password);
        console.log(result);
        this.router.navigate(['pages/apps']);
    }

    async logout(){
        await this.afAuth.signOut();
        this.router.navigate(['pages/auth/login']);
    }

    async googleSignin() 
    {
        const provider = new auth.auth.GoogleAuthProvider();
        const credential = await this.afAuth.signInWithPopup(provider);
        return this.updateUserData(credential.user);
    }
        
    private updateUserData(user)
    {
        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
        const data = { 
            uid: user.uid, 
            email: user.email, 
            displayName: user.displayName, 
            photoURL: user.photoURL,
            roles: {
                customer: true
              }
        } 
        return userRef.set(data, { merge: true })
    }

    isAdmin(user: User): boolean 
    {
        const allowed = ['admin'];
        return this.checkAuthorization(user, allowed);
    }

    isAgent(user: User): boolean 
    {
        const allowed = ['agent'];
        return this.checkAuthorization(user, allowed);
    }

    private checkAuthorization(user: User, allowedRoles: string[]): boolean 
    {
        if (!user) 
            return false;
        for (const role of allowedRoles) 
        {
        if ( user.roles[role] ) 
            return true;
        }
        return false;
    }



    async register(email: string, password: string) {
        var result = await this.afAuth.createUserWithEmailAndPassword(email, password);
        this.sendEmailVerification();
    }

    async sendEmailVerification() {
        await (await this.afAuth.currentUser).sendEmailVerification();
        this.router.navigate(['admin/mail-confirm']);
    }

    async sendPasswordResetEmail(passwordResetEmail: string) {
        return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
     }

    get isLoggedIn(): boolean {
        const  user  =  JSON.parse(localStorage.getItem('user'));
        return  user  !==  null;
    }

        
}